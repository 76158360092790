<template>
  <div class="prodect">
    <div style="height: 80px"></div>
    <banner title="产品中心" subTitle="Product Center" />
    <div class="body-box">
      <div class="c-width cf w1200">
        <div class="l-menu fl">
          <p class="menu-name">产品中心</p>
          <p
            v-for="(item, index) in data"
            :key="item.id"
            class="link"
            :class="[item.id == fid ? 'on' : '']"
            @click="selLink({ index }, item.id)"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="r-box fr">
          <div class="r-header cf">
            <h4 class="fl">{{ data[index].title }}</h4>
            <div class="fr path">首页 > 产品中心 > {{ data[index].title }}</div>
          </div>
          <div class="r-context">
            <div class="cf" v-if="!isDetail">
              <div
                class="item fl"
                v-for="(item, index) in data[index].cpList"
                :key="item.id"
                @click="teProDetail(index)"
              >
                <img :src="item.src" alt="" />
                <p>{{ item.name }}</p>
              </div>
            </div>
            <div class="detail" v-if="isDetail">
                <img style="width:100%" :src=" data[index].cpList[itemIndex].contextimg1" alt="" />
                <p style="color:#333333;font-size:20px;font-weight:700;margin:20px 0 10px">{{data[index].cpList[itemIndex].gg}}</p>
                <img style="width:630px" :src=" data[index].cpList[itemIndex].contextimg2"  alt="" />
                <div v-html=' data[index].cpList[itemIndex].context'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import data from "../data/data";
import banner from "@/components/banner";
export default {
  components: {
    banner,
  },
  data() {
    return {
      data: data,
      fid: 1,
      index: 0,
      itemIndex: 0,
      isDetail:false,
    };
  },
  methods: {
    selLink: function (index, fid) {
      console.log(this);
      this.index = index.index;
      this.fid = fid;
      this.isDetail = false
    },
    teProDetail: function (index) {
      this.itemIndex = index;
      this.isDetail = true;
      // this.$router.push({
      //   path: "/product-detail",
      //   query: { itemIndex: index.index, index: this.index },
      // });
    },
  },
};
</script>
<style scoped>
@import "../style.css";
</style>